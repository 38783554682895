import * as React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const PageSeo = ({ pageTitle, children, pageDescription, pageKeywords }) => {
    return (
      <Helmet htmlAttributes={{ lang: 'en-us' }}>
      <script>
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NQFJDSTL');
        `}
      </script>
      <script type="application/ld+json">
        {`{"@context":"https://schema.org/","url":"https://www.whyneoadjuvant.com/","@type":"MedicalWebPage","name":"Neoadjuvant Therapy + Surgery Together","description":"Neoadjuvant cisplatin-based chemotherapy is a systemic therapy given before surgery to help downstage the tumor and may reduce the risk of recurrence."}`}
      </script>
      <script type="application/ld+json">
        {`{"@context": "https://schema.org","@type": "FAQPage","mainEntity": [{"@type": "Question","name": "What is the role of the multidisciplinary team in MIBC?","acceptedAnswer": {"@type": "Answer","text": "The multidisciplinary approach in MIBC creates a platform to discuss potential risks and benefits of all accepted forms of therapy. After taking into account patient preference, the urologist and medical oncologist should determine if the patient is a good candidate for neoadjuvant therapy + surgery."}},{"@type": "Question","name": "Why neoadjuvant therapy?","acceptedAnswer": {"@type": "Answer","text": "The risk of recurrence after surgery is a clinical concern. In a retrospective single-institution study of 2,315 patients with urothelial carcinoma, soft tissue recurrence outside of the urinary tract was assessed in 617 patients after undergoing radical cystectomy between 2000 and 2014. Disease characteristics, including consensus T-stage and histology, were studied. In addition, select treatment factors studied were use of intravesical therapy, preoperative systemic chemotherapy, adjuvant chemotherapy, and salvage chemotherapy. The majority of soft tissue recurrences outside of the urinary tract took place ≤3 years after surgery."}},{"@type": "Question","name": "What is the potential benefit of neoadjuvant chemotherapy?","acceptedAnswer": {"@type": "Answer","text": "According to the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®): National Comprehensive Cancer Network® (NCCN®) recommends cisplatin-based neoadjuvant chemotherapy followed by radical cystectomy for certain patients with stage ll or lllA bladder cancer."}}]}`}
      </script>
      <script type="application/ld+json">
        {`{"@context":"https://schema.org/","url":"https://www.whyneoadjuvant.com/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for U.S. health care professionals."}`}
      </script>
        <title lang="en">{ pageTitle }</title>
  
        {/* <!-- OneTrust Cookies Consent Notice start for whyneoadjuvant.com --> */}
          
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script={process.env.GATSBY_PRODUCTION_BUILD ? '9f6632f9-5c27-4119-9b00-3bf4bca8b2c4' : '018ee57c-a0cd-7097-a4ca-66e821570ac4-test'}></script>

          <script type="text/javascript">
          </script>

          <script type="text/javascript">
          {`
            function OptanonWrapper() { } 
          `}
          </script>
          
          {/* <!-- OneTrust Cookies Consent Notice end for whyneoadjuvant.com --> */}
       

        { pageDescription &&
          <meta name="description" content={ pageDescription }/>
        }

        { pageKeywords &&
          <meta name="keywords" content={ pageKeywords } />
        }

    
        {/* <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
            {'gtm.start': new Date().getTime(),event:'gtm.js'}
            );var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-PPTFBL7');`
        }
        </script> */}

        {/* Meta Pixel Code  */}
        <script>
        {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '2492113600935709');
        fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=2492113600935709&ev=PageView&noscript=1"/>`}
        </noscript>
        {/* End Meta Pixel Code  */}
  
        {/* To support multiple schemas. Make sure that `` exists with {} for any script. */}
        {children}
  
      </Helmet>
    );
  };
  
  
  PageSeo.propTypes = {
    pageTitle: PropTypes.string,
  };
  
  PageSeo.defaultProps = {
    pageTitle: 'Merck',
  };
  
  
  export default PageSeo;
